var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-0 px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.orders,"headers":_vm.headers,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.connectorId",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"info","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.createdBy.commercialName)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('OrderStatusComponent',{attrs:{"orderType":item.orderType}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }