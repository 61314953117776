






























































import {Vue, Component, Mixins, Prop} from 'vue-property-decorator'
import PaginationMixin from "@/mixins/PaginationMixin"
import Handler from "@/handlers/Handler";
import ClientService from "@/services/ClientService";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Client from "@/models/Client";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import User from "@/models/User";

@Component
export default class CommercialClientsTab extends Mixins(PaginationMixin) {
    @Prop() readonly commercial!: User
    lang: any = getModule(LangModule).lang
    loading: boolean = false
    clients: MultipleItem<Client> = { items: [], totalItems: 0 }

    headers = [
        { align: "center", divider: true, width: "50px", text: "Id", value: "id" },
        { align: "center", divider: true, width: "auto", text: "Email", value: "email" },
        { align: "center", divider: true, width: "auto", text: "Nombre", value: "name" },
        { align: "center", divider: true, width: "auto", text: "Nombre Comercial", value: "commercialName" },
        { align: "center", divider: true, width: "auto", text: "Estado", value: "enable" }
    ]

    created() {
        this.refresh()
    }

    async refresh() {
        await Handler.getItems(this, this.clients, () =>
            ClientService.getClientsUltimate(this.page - 1, this.size, this.search, this.commercial.id!, null)
        )
        this.setPageCount(this.clients.totalItems!!)
    }

    rowClick(client: Client) {
        this.$router.push({path: "/clients/" + client.id})
    }

}
