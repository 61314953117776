var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clients.items,"hide-default-footer":"","loading":_vm.loading,"show-select":false,"loading-text":_vm.lang.loading,"search":_vm.search,"no-results-text":_vm.lang.noResults,"no-data-text":_vm.lang.noResults,"disable-sort":"","height":"300px"},on:{"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.commercialName",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.enable",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"item.enable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.enable ? _vm.lang.enabled : _vm.lang.disabled)+" ")]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex pa-5"},[_c('span',{staticClass:"text-body-1 font-weight-regular grey--text text--darken-3 my-2"},[_vm._v("Mostrando "+_vm._s(_vm.clients.items.length)+" de "+_vm._s(_vm.clients.totalItems)+" clientes")]),_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }